import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, List, Table } from 'antd';

import BaseCard from '../../../../../../../../../components/BaseCard/BaseCard';
import BaseHeader from "../../../../../../../../../components/BaseHeader/BaseHeader";
import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';

import { renderMoment } from "../../../../../../../../../services/date-service/date.service";
import { getScrollHeight, renderUScurrency } from "../../../../../../../../../services/util-service/util.service";

import './MailMainView.scss';

class MailMainView extends Component {

  tableRowHeight = 65;

  renderActions = (item, data) => {
    const { t } = this.props;
    return (
      <div className="MailMainViewActions">
        <Button onClick={this.exportAll.bind(this, data)}>
          {t('MailMainView.all')}
        </Button>
        <Button onClick={this.exportChanged.bind(this, data)}>
          {t('MailMainView.changedExport')}
        </Button>
      </div>
    );
  };

  renderLastSyncDate = (lastSync) => {
    return renderMoment(lastSync);
  };

  renderNumber = (item) => renderUScurrency(item);

  columns = [
    {
      dataIndex: 'name',
      title: this.props.t('MailMainView.property'),
      width: 200
    },
    {
      dataIndex: 'contacts_count',
      title: this.props.t('MailMainView.total'),
      render: this.renderNumber,
      width: 200
    },
    {
      dataIndex: 'lastSync',
      render: this.renderLastSyncDate,
      title: this.props.t('MailMainView.lastSync'),
      width: 200
    },
    {
      dataIndex: 'contacts_changed_count',
      title: this.props.t('MailMainView.changed'),
      render: this.renderNumber,
      width: 200
    },
    {
      align: 'center',
      dataIndex: 'actions',
      title: this.props.t('MailMainView.export'),
      render: this.renderActions,
      width: 200
    }
  ];

  exportAll = data => {
    console.log(data, 'exportAll');
  };

  exportChanged = data => {
    console.log(data, 'exportChanged');
  };

  getBaseCardFooter = () => {
    const { t } = this.props;
    return (
      <div className="MailCard-footer">
        <Button onClick={this.exportAll}>
          {t('MailMainView.exportAll')}
        </Button>
        <Button onClick={this.exportChanged}>
          {t('MailMainView.exportChanged')}
        </Button>
      </div>
    );
  };

  getListCardFooter = (item) => {
    return (
      <div className="ListCardCFooter">
        {this.renderActions(item, item)}
      </div>
    );
  };

  getListItemCardHeader = (item) => {
    const { id } = item;
    const { t } = this.props;
    return (
      <BaseHeader title={t('MailMainView.title', { id: id })} />
    );
  };

  getScrollHeight = () => {
    const { contentHeight, listInfo } = this.props;
    const reductionHeight = 54;
    return getScrollHeight(contentHeight, listInfo.data, this.tableRowHeight, reductionHeight);
  };

  renderMailsList = () => {
    return (
      <List
        dataSource={this.props.listInfo.data}
        renderItem={this.renderMailsListItem}
        split={false}
        itemLayout='vertical'
      />
    );
  };

  renderMailsListItem = item => {
    const { id, name, contacts_count, lastSync, contacts_changed_count } = item;
    const { t } = this.props;
    return (
      <List.Item key={id}>
        <BaseCard
          title={this.getListItemCardHeader(item)}
          footer={this.getBaseCardFooter()}
        >
          <div className='ListCardWrapper'>
            <p>
              <span className='ListItemLabel'>
                {t('MailMainView.property')}:
              </span>
              {name}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('MailMainView.total')}:
              </span>
              {this.renderNumber(contacts_count)}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('MailMainView.lastSync')}:
              </span>
              {this.renderLastSyncDate(lastSync)}
            </p>
            <p>
              <span className='ListItemLabel'>
                {t('MailMainView.changed')}:
              </span>
              {this.renderNumber(contacts_changed_count)}
            </p>
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  renderMailsTable = () => {
    return (
      <Table
        dataSource={this.props.listInfo.data}
        rowKey='id'
        bordered
        columns={this.columns}
        pagination={false}
        scroll={this.getScrollHeight()}
      />
    );
  };

  render() {
    const { t } = this.props;
    return (
      <LoadingWrapper
        className='MailMainView'
        dataLoaded={this.props.listInfo.dataLoaded}
        isLoading={this.props.listInfo.loading}
        loadingMsg={t('MailMainView.loadingPleaseWait')}
      >
        <div className='MailViewBody'>
          {this.renderMailsTable()}
          {this.renderMailsList()}
        </div>
      </LoadingWrapper>
    );
  }
}

export default withTranslation()(MailMainView);