import React, { Component } from 'react';
import { List, Table } from "antd";
import PropTypes from 'prop-types';

import { getScrollHeight, renderUScurrency } from "../../../../../../../../../services/util-service/util.service";

class PayoutsDisplayBaseAbstract extends Component {
  rowHeight = 54;

  calcTableHeight = (data) => {
    let tableHeight;
    const { contentHeight } = this.props;
    if (data) {
      tableHeight = getScrollHeight(contentHeight, data, this.rowHeight, this.getReductionHeight());
    }
    return tableHeight;
  };

  getColumns = () => [];

  getReductionHeight = () => 0;

  getRowKey = item => '';

  renderList = (data, renderItem) => {
    return (
      <List dataSource={data}
        renderItem={renderItem}
        split={false}
        itemLayout='vertical' />
    );
  };

  renderListItemField = (label, value) => {
    return (
      <div>
        <span className='ListItemLabel'>{label}:</span>
        {value}
      </div>
    );
  };

  renderNumber = (item) => renderUScurrency(item);

  renderStatus = status => {
    //// TODO: Set state?
    // this.state = { status: status };

    // this.setState({ status: status });
    const statusNum = +status;
    if (isNaN(statusNum)) return '';

    let label = '';
    if (statusNum === 0) {
      label = 'Estimate';
    } else if (statusNum === 1) {
      label = 'Pending Approval';
    } else if (statusNum === 2) {
      label = 'Final';
    }
    return label;
  }

  renderTable = (data) => {
    const tableHeight = this.calcTableHeight(data);
    const props = {
      dataSource: data,
      rowKey: this.getRowKey,
      bordered: true,
      columns: this.getColumns(),
      pagination: false
    };
    if (tableHeight) {
      props.scroll = { tableHeight }
    }
    return (
      <Table {...props} />
    );
  };

  render() {
    return (
      <div />
    );
  }
}

PayoutsDisplayBaseAbstract.propTypes = {
  contentHeight: PropTypes.number
};

export default PayoutsDisplayBaseAbstract;
