import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { Table, Button, Icon } from "antd";

import { getPayoutsByLicensorTop, getPayoutsByLicensorTopExport } from '../../../../../../../../../services/payouts-service/payouts.service';
import { getPayoutsRoute } from '../../../../../../../../../services/navigation/payouts-navigation/payouts-navigation-routes.service';
import { renderMoment } from '../../../../../../../../../services/date-service/date.service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../services/notification-service/notification.service';

import LoadingWrapper from '../../../../../../../../../components/LoadingWrapper/LoadingWrapper';
import BaseFooter from '../../../../../../../../../components/BaseFooter/BaseFooter';
import BaseNavigationItem from '../../../../../../../../../components/BaseNavigationItem/BaseNavigationItem';
import { renderUScurrency } from '../../../../../../../../../services/util-service/util.service';

const columns = [{
  title: 'ID',
  dataIndex: 'licensor',
  render: licensor => licensor?.id || '/',
  key: 'licensor_id',
}, {
  title: 'Licensor',
  dataIndex: 'licensor',
  render: licensor => licensor?.name || '/',
  key: 'licensor_name',
}, {
  title: 'Gross',
  dataIndex: 'gross',
  render: val => renderUScurrency(val),
  key: 'gross',
}, {
  title: 'Marketing',
  dataIndex: 'marketing_fee',
  key: 'marketing_fee',
}, {
  title: 'Processing',
  dataIndex: 'processing_fee',
  key: 'processing_fee',
}, {
  title: 'Split',
  dataIndex: 'split',
  key: 'split',
}, {
  title: 'Payout',
  dataIndex: 'payout',
  render: val => renderUScurrency(val),
  key: 'payout',
}, {
  title: 'View Time',
  dataIndex: 'view_time',
  render: time => {
    const days = Math.floor(time / (60 * 60 * 24));
    const hours = Math.floor((time / (60 * 60)) % 24);
    const minutes = Math.floor((time / 60) % 60);

    const daysText = `${days} days`;
    const hoursText = `${hours} hours`;
    const minutesText = `${minutes} minutes`;

    const compensateForValueIfMissing = (value, valueText) => value ? valueText : '';

    return `${compensateForValueIfMissing(days, daysText)} ${compensateForValueIfMissing(hours, hoursText)} ${compensateForValueIfMissing(minutes, minutesText)}`;
  },
  key: 'view_time',
}];

const LicensorTopView = (props) => {
  const { location } = props;
  const split = location.pathname.split('/');
  const year = split[5];
  const month = split[6];
  const startDate = moment(`${year}/${month}`, 'YYYY/M').startOf('month').format('YYYY-MM-DD');
  const endDate = moment(`${year}/${month}`, 'YYYY/M').endOf('month').format('YYYY-MM-DD');

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMsg, setLoadingMsg] = useState('Loading payouts by licensor. Please wait');
  const [pagination, setPagination] = useState({ page: 1, pageCount: 1 });

  useEffect(() => {
    setLoading(true);
    setLoadingMsg('Loading payouts by licensor. Please wait');
    setData([]);

    getPayoutsByLicensorTop(startDate, endDate, pagination.page)
      .then((resp = {}) => {
        const data = resp.data?.data?.by_licensor || [];
        const pagination = resp.data?.data?.pagination || {};

        const pagiData = {
          page: pagination.current_page || 1,
          pageCount: pagination.last_page,
          perPage: pagination.per_page,
          recordCount: pagination.total
        };

        setData(data);
        setPagination(prevState => ({
          ...prevState,
          ...pagiData
        }));
      })
      .catch((err = {}) => {
        displayErrorNotification({
          duration: 0,
          message: err.message,
          description: err.response?.data?.error,
        });
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [pagination.page, startDate, endDate]);

  const onPageChange = (page) => {
    setPagination(prevState => ({
      ...prevState,
      page
    }));
  };

  const renderFooter = () => {
    const props = {
      className: 'PayoutPropertyDetailsFooter',
      currentPage: pagination.page,
      onChange: onPageChange,
      pageCount: pagination.pageCount,
      pageSize: pagination.perPage,
      recordCount: pagination.recordCount
    };
    return <BaseFooter {...props} />
  };

  const onExport = () => {
    if (loading) return;

    setLoading(true);
    setLoadingMsg('Creating export CSV. Please wait');

    getPayoutsByLicensorTopExport(startDate, endDate)
      .then((resp = {}) => {
        displaySuccessNotification({
          duration: 0,
          message: 'Export success!',
          description: (
            <CSVLink data={resp.data} filename={"exported-payouts-by-licensor.csv"}>Click to download CSV file</CSVLink>
          )
        });
      })
      .catch((err = {}) => {
        displayErrorNotification({
          duration: 0,
          message: err.message,
          description: err.response?.data?.error,
        });
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const getActions = () => {
    return [
      <Button onClick={onExport}
        key="export">
        <Icon type="save" />
        Export
      </Button>
    ]
  };

  const getRoutes = () => {
    const routes = [{
      url: getPayoutsRoute(),
      text: 'payouts'
    }, {
      text: `${renderMoment(startDate)} - ${renderMoment(endDate)}`
    }];

    return routes;
  };

  return (
    <>
      <BaseNavigationItem routes={getRoutes()}>
        {getActions()}
      </BaseNavigationItem>
      <LoadingWrapper
        style={{ display: 'grid', minHeight: '85%' }}
        dataLoaded={!loading}
        loadingMsg={loadingMsg}>
        <div style={{ maxHeight: '63vh', overflow: 'auto' }}>
          <Table
            dataSource={data}
            bordered={true}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.licensor?.id || new Date().getTime()}
          />
        </div>
        {renderFooter()}
      </LoadingWrapper>
    </>
  )
};

export default LicensorTopView;