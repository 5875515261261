import React from 'react';
import { withTranslation } from "react-i18next";
import { List } from "antd";
import PropTypes from 'prop-types';

import BaseFooter from "../../../../../../../../../../components/BaseFooter/BaseFooter";
import BaseCard from "../../../../../../../../../../components/BaseCard/BaseCard";
import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";
import PayoutsDisplayBaseAbstract from "../../PayoutsDisplayBaseAbstract/PayotsDisplayBaseAbstract";

import { getMovieDetailsRoute } from "../../../../../../../../../../services/navigation/movies-navigation/movies-navigation-routes.service";
import { renderUScurrency } from '../../../../../../../../../../services/util-service/util.service';

import './PayoutsByMovieMainView.scss';

class PayoutsByMovieMainView extends PayoutsDisplayBaseAbstract {

  compensateForValueIfMissing = (value, valueText) => {
    return value ? valueText : '';
  };

  getColumns = () => {
    const { t } = this.props;
    return [{
      dataIndex: ['movie', 'title'],
      title: t('PayoutsByMovieMainView.movie')
    }, {
      dataIndex: 'view_time',
      title: t('PayoutsByMovieMainView.time'),
      render: this.renderTime
    }, {
      dataIndex: 'gross',
      title: t('PayoutsByMovieMainView.gross'),
      render: this.renderNumber
    }, {
      dataIndex: 'marketing_fee',
      title: t('PayoutsByMovieMainView.marketing'),
      render: this.renderMarketing
    }, {
      dataIndex: 'processing_fee',
      title: t('PayoutsByMovieMainView.processing'),
      render: this.renderProcessing
    }, {
      dataIndex: 'split',
      title: t('PayoutsByMovieMainView.net'),
      render: this.renderNet
    }, {
      title: t('PayoutsByMovieMainView.action'),
      align: 'center',
      key: 'action',
      render: this.renderActionItem
    }];
  };

  getListCardFooter = (item) => {
    return (
      <div className="PayoutsByMovie-listCard-footer">
        {this.renderActionItem(item)}
      </div>
    );
  };

  getReductionHeight = () => 55 + 42 + 54;

  getRowKey = item => item.movie.id;

  renderActionItem = item => {
    const { movie } = item;
    const { t } = this.props;
    return (
      <LinkButton href={getMovieDetailsRoute(movie.id)}>
        {t('PayoutsByMovieMainView.viewMovieDetails')}
      </LinkButton>
    );
  };

  renderFooter = () => {
    const { data, onPageChange } = this.props;
    const { page, pageCount, perPage, recordCount } = data;
    const props = {
      className: 'PayoutLicensorDetailsFooter',
      currentPage: page,
      onChange: onPageChange,
      pageCount,
      pageSize: perPage,
      recordCount
    };
    return (
      <BaseFooter {...props} />
    );
  };

  renderLicensorListItem = item => {
    const { movie, view_time, gross, marketing_fee, processing_fee, split } = item;
    const { t } = this.props;
    const { id, title } = movie;
    return (
      <List.Item key={id}>
        <BaseCard title={t('PayoutsByMovieMainView.title', { name: title })}
          footer={this.getListCardFooter(item)}>
          <div className='ListCardWrapper'>
            {this.renderListItemField(t('PayoutsByMovieMainView.movie'), title)}
            {this.renderListItemField(t('PayoutsByMovieMainView.time'), this.renderTime(view_time))}
            {this.renderListItemField(t('PayoutsByMovieMainView.gross'), this.renderNumber(gross))}
            {this.renderListItemField(t('PayoutsByMovieMainView.marketing'), this.renderMarketing(marketing_fee, item))}
            {this.renderListItemField(t('PayoutsByMovieMainView.processing'), this.renderProcessing(processing_fee, item))}
            {this.renderListItemField(t('PayoutsByMovieMainView.net'), this.renderNet(split, item))}
          </div>
        </BaseCard>
      </List.Item>
    );
  };

  renderMarketing = (item, record) => {
    const marketing = item * 100 + '%';
    const { marketing_fee_applied } = record;
    return `${marketing} / ${renderUScurrency(marketing_fee_applied)}`;
  };

  renderNet = (item, record) => {
    const split = item * 100 + '%';
    const { payout } = record;
    return `${split} / ${renderUScurrency(payout)}`;
  };

  renderProcessing = (item, record) => {
    const processing = item * 100 + '%';
    const { processing_fee_applied } = record;
    return `${processing} / ${renderUScurrency(processing_fee_applied)}`;
  };

  renderTime = time => {
    const { t } = this.props;
    const days = Math.floor(time / (60 * 60 * 24));
    const hours = Math.floor((time / (60 * 60)) % 24);
    const minutes = Math.floor((time / 60) % 60);

    const daysText = `${days} ${t('PayoutsByMovieMainView.days')}`;
    const hoursText = `${hours} ${t('PayoutsByMovieMainView.hours')}`;
    const minutesText = `${minutes} ${t('PayoutsByMovieMainView.minutes')}`;

    return `${this.compensateForValueIfMissing(days, daysText)} ${this.compensateForValueIfMissing(hours, hoursText)} ${this.compensateForValueIfMissing(minutes, minutesText)}`;
  };

  render() {
    const { movies } = this.props.data;
    return (
      <div className="PayoutsByMovieMainView">
        <div className='PayoutsByMovieBody'>
          {this.renderTable(movies)}
          {this.renderList(movies, this.renderLicensorListItem)}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}

PayoutsByMovieMainView.propTypes = {
  contentHeight: PropTypes.number,
  data: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default withTranslation()(PayoutsByMovieMainView);
